@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=BioRhyme:wght@200..800&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Staatliches&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Podkova:wght@400..800&display=swap");

@layer base {
  h1 {
    @apply md:text-5xl text-4xl font-BioRhyme;
  }
  h2 {
    @apply md:text-3xl text-xl font-Montserrat;
  }
  h3 {
    @apply md:text-2xl text-lg font-Montserrat;
  }
  h4 {
    @apply md:text-lg font-Montserrat;
  }
  p {
    @apply font-Montserrat;
  }
}
